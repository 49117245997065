import { motion } from "framer-motion";
import React from "react";
import TitlePages from "../../components/TitlePage";

const NotFound = () => {
  return (
    <>
      <motion.div
        className={"transition"}
        initial={{ x: 0 }}
        animate={{ x: "100%" }}
        exit={{ x: 0 }}
        transition={{
          transition: 3,
          type: "spring",
          stiffness: 200,
        }}
      />
      <div className="containerDiv">
        <TitlePages title={"Ups! Esta página no existe"} expand />
      </div>
    </>
  );
};

export default NotFound;
