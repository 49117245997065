import { motion } from "framer-motion";
import React from "react";
import { adds } from "../../assets";
import CTAsec from "../../components/CTAsec";
import TitlePages from "../../components/TitlePage";
import List from "./List";
import Teconlogias from "./Tecnologias";
import { useTranslate } from "../../assets/utils/useTranslate";

const Servicios = ({ variants }) => {
  const wordTranslated = useTranslate("services");
  const { miniTitle, title, subtitle } = wordTranslated;
  return (
    <>
      <motion.div
        className={"transition"}
        initial={{ x: 0 }}
        animate={{ x: "100%" }}
        exit={{ x: 0 }}
        transition={{
          transition: 3,
          type: "spring",
          stiffness: 200,
        }}
      />
      <div className="containerDiv">
        <TitlePages
          title={title}
          toptitle={miniTitle}
          para={subtitle}
          variants={variants}
          expand
        />
        <img className="blur blurSer1" src={adds.blur1} alt="Blur" />
        <img className="blur blurSer2" src={adds.blur1} alt="Blur" />

        <List variants={variants} />
        <Teconlogias variants={variants} />
        <CTAsec href={"/contacto"} type="link" variants={variants} />
      </div>
    </>
  );
};

export default Servicios;
