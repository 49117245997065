import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { logos } from "../../../assets";
import { parrafos } from "./data";
import { useTranslate } from "../../../assets/utils/useTranslate";

const Parrafo = ({ variants }) => {
  const wordTranslated = useTranslate("whoWeAre");
  const dynamicParrafos = parrafos(wordTranslated);
  return (
    <div className="parrafoqs section relative noPadTop">
      <div className="ext container-lg flex flexRow gap justifySpace flexWrap">
        <div className="flex flexCol parrafoDiv">
          {dynamicParrafos.map((para, index) => (
            <motion.p
              variants={variants}
              initial={"initial"}
              whileInView={"ani"}
              viewport={{ once: true }}
              custom={index * 0.2 + 0.8}
              key={index}
            >
              {para}
            </motion.p>
          ))}
        </div>
        <div className="flex flexCol">
          <motion.img
            variants={variants}
            initial={{ scale: 0, y: -100, opacity: 0, rotate: 0 }}
            whileInView={{
              scale: 1,
              y: 0,
              opacity: 1,
              rotate: -15,
              transition: {
                delay: 1,
                transition: 4,
                type: "spring",
                stiffness: 200,
              },
            }}
            viewport={{ once: true }}
            src={logos.isoWhite.src}
            alt={logos.isoWhite.alt}
            className={"logoQs"}
          />
        </div>
      </div>
    </div>
  );
};

export default Parrafo;
