import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Contacto from "../../pages/Contacto";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import QuienesSomos from "../../pages/QuienesSomos";
import Servicios from "../../pages/Servicios";
import { AnimatePresence } from "framer-motion";
import Exito from "../../pages/Exito";

const AniRoutes = () => {
  const animation = {
    initial: {
      skew: "3deg, -6deg",
      y: -60,
      opacity: 0,
    },
    ani: (custom) => ({
      skew: "0deg, 0deg",
      y: 0,
      opacity: 1,
      transition: {
        delay: custom,
        transition: 4,
        type: "spring",
        stiffness: 200,
      },
    }),
  };

  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home variants={animation} />} />
        <Route path="/servicios" element={<Servicios variants={animation} />} />
        <Route
          path="/quienes-somos"
          element={<QuienesSomos variants={animation} />}
        />
        <Route
          path="/casos-de-exito"
          element={<Exito variants={animation} />}
        />
        <Route path="/contacto" element={<Contacto variants={animation} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AniRoutes;
