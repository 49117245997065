import React from "react";
import { motion } from "framer-motion";
import { technologies } from "../../../assets";
import { useTranslate } from "../../../assets/utils/useTranslate";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.css";

const Teconlogias = ({ variants }) => {
  const wordTranslated = useTranslate("services");
  const { tecnology } = wordTranslated;
  return (
    <div className="section darkSec noPadTop">
      <div className="container-lg flex flexCol">
        <motion.h3
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.3}
          className="textCenter"
        >
          {tecnology}
        </motion.h3>

        <Swiper
          className="techSwiper"
          modules={[Autoplay]}
          spaceBetween={50}
          grabCursor={false}
          speed={5000}
          autoplay={{
            delay: 5,
          }}
          loop
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          }}
        >
          {Object.keys(technologies).map((key, index) => (
            <SwiperSlide
              key={key}
              style={{ animationDelay: `${Math.random() * (0 - 15)}s` }}
            >
              <motion.img
                variants={variants}
                initial={"initial"}
                whileInView={"ani"}
                viewport={{ once: true }}
                custom={index * 0.2}
                src={technologies[key].src}
                alt={technologies[key].alt}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Teconlogias;
