import React from "react";
import "./index.css";

const Skeleton = ({ items }) => {
  const lenghtRender = () => {
    let skeletonItems = [];
    for (let i = 0; i < items; i++) {
      skeletonItems.push(
        <div
          key={i}
          className="skeleton"
          style={{ animationDelay: `${i * 0.2}s` }}
        ></div>
      );
    }
    return skeletonItems;
  };

  return (
    <>
      <div className="skeletonDiv">{lenghtRender()}</div>
    </>
  );
};

export default Skeleton;
