import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AniRoutes from "./components/AniRoutes";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import { LanguajeContext } from "./assets/store/context";


function App() {
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const [languaje, setLanguaje] = useState(localStorage.getItem('language')||'ENG')


  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  const contextLanguajeValue = {
    languaje,
    setLanguaje
  }
  return (
    <div className="App">
      <BrowserRouter>
        <LanguajeContext.Provider value={contextLanguajeValue}>

          {/* Header */}
          <Header windowDimension={windowDimension} />
          {/* Body */}
          <AniRoutes />
          {/* Footer */}
          <Footer windowDimension={windowDimension} />

        </LanguajeContext.Provider>
        <ScrollToTop />

      </BrowserRouter>
    </div>
  );
}

export default App;