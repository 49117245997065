import React, { useState } from "react";
import "./index.css";
import { logos } from "../../assets";
import { Link, NavLink } from "react-router-dom";
import { DropDownLanguaje } from "../dropdown/DropDownLanguaje";
import { useTranslate } from "../../assets/utils/useTranslate";

const Header = ({ windowDimension }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wordTranslated = useTranslate('header')

  const MenuList = [
    {
      href: "/",
      text: wordTranslated.home,
    },
    {
      href: "/servicios",
      text: wordTranslated.services,
    },
    {
      href: "/quienes-somos",
      text: wordTranslated.whoWeAre,
    },
    {
      href: "/casos-de-exito",
      text: wordTranslated.successfull,
    },
    {
      href: "/contacto",
      text: wordTranslated.contact,
    },
  ];


  
  return (
    <header className="header">
      <div className="container-lg flex flexRow justifySpace alignCenter">
        <Link className="logoHeader" to={"/"}>
          <img src={logos.logoWhite.src} alt={logos.logoWhite.alt} />
        </Link>
        <div
          className={isOpen ? "menuOpen menuButton" : "menuButton"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav
          className={
            (windowDimension.winWidth <= 850 && isOpen ? "navOpen " : "") +
            "menu flex flexRow gap"
          }
        >
          {MenuList.map((menu, index) => (
            <NavLink
              onClick={() => setIsOpen(false)}
              className={({ isActive }) =>
                isActive ? "menuLink menuActive" : "menuLink"
              }
              to={menu.href}
              key={index}
              style={{
                transitionDelay: index * 0.2 + "s",
              }}
              end
            >
              {menu.text}
            </NavLink>
          ))}
          <DropDownLanguaje/>
          <img
            className="isoMobile"
            src={logos.isoWhite.src}
            alt={logos.isoWhite.alt}
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;