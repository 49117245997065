import { motion } from "framer-motion";
import React from "react";
import TitlePages from "../../components/TitlePage";
import Galeria from "./Galeria";
import Parrafo from "./Parrafo";
import CTAsec from "../../components/CTAsec";
import { useTranslate } from "../../assets/utils/useTranslate";

const QuienesSomos = ({ variants }) => {
  const wordTranslated = useTranslate("whoWeAre");
  const { miniTitle, title } = wordTranslated;
  return (
    <>
      <motion.div
        className={"transition"}
        initial={{ x: 0 }}
        animate={{ x: "100%" }}
        exit={{ x: 0 }}
        transition={{
          transition: 3,
          type: "spring",
          stiffness: 200,
        }}
      />
      <div className="containerDiv">
        <TitlePages
          title={title}
          toptitle={miniTitle}
          variants={variants}
          expand
        />
        <Parrafo variants={variants} />
        <Galeria variants={variants} />
        <CTAsec href={"/contacto"} type="link" variants={variants} />
      </div>
    </>
  );
};

export default QuienesSomos;
