import React, { useContext, useState } from "react";
import { LanguajeContext } from "../../assets/store/context";
import { motion } from "framer-motion";
import {animation} from './animation';
import "./index.css";

export const DropDownLanguaje = () => {
  const { languaje, setLanguaje } = useContext(LanguajeContext);
  const [isOpen, setIsOpen] = useState(false);

  const languages = ["ESP", "ENG"];

  const handlePress = () => {
    setIsOpen(!isOpen);
  };

  const handleClickLanguages = (language) => {
    localStorage.setItem("language", language);
    setLanguaje(language);
    setIsOpen(false);
  };

  return (
    <div className="container" onClick={() => handlePress()} >
      <span className="selected-title-active selected-title">
        {languaje}
      </span>
      { isOpen ?
      <motion.div
        className="container-elements-language"
        variants={animation}
        initial={"initial"}
        animate={"ani"}
        custom={0}
        >
          { languages.filter(el=> el !==languaje).map((el) => {
              return (
                <span
                  key={el}
                  className="language-text"
                  onClick={() => handleClickLanguages(el)}
                >
                    {el}
                </span>
            )
          })
        }
        </motion.div>
         : null}
    </div>
  );
};