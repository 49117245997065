import { useState, useEffect } from "react";
import { useTranslate } from "../../../assets/utils/useTranslate.jsx";
import { casosData } from "./data.js";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper";
import { motion } from "framer-motion";

import Skeleton from "../../../components/Skeleton";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "./index.css";

const Casos = () => {
  const wordTranslated = useTranslate("success");
  const dynamicCasos = casosData(wordTranslated);
  const [casosLoaded, setCasosLoaded] = useState(false);

  useEffect(() => {
    setCasosLoaded(true);
  }, []);

  return (
    <div className="casos section relative noPadTop">
      <div className="container-lg flex flexCol">
        {!casosLoaded ? (
          <Skeleton items={6} />
        ) : Array.isArray(dynamicCasos) && dynamicCasos.length > 0 ? (
          <div className="casosDiv">
            {dynamicCasos.map((caso) => (
              <motion.div className="casoscard" key={caso.id}>
                <div className="contentCaso">
                  <h3>{caso.nombre}</h3>
                  <p>{caso.descripcion}</p>
                  <div className="challenge">
                    <h4>Challenge</h4>
                    <p>{caso.challenge}</p>
                  </div>
                  <div className="tecno">
                    {Array.isArray(caso.tecnologias) ? caso.tecnologias.map((tecnologia, index) => (
                      <span key={index}>{tecnologia}</span>
                    )) : null}
                  </div>
                </div>
                <div className="galCaso">
                  <Swiper
                    className="galeriaCasos"
                    effect={"coverflow"}
                    modules={[]}
                    grabCursor={true}
                    speed={1500}
                    centeredSlides={true}
                    autoplay={{
                      delay: 3000,
                    }}
                    loop
                    slidesPerView={1}
                  >
                    {Array.isArray(caso.images) ? caso.images.map((img, index) => (
                      <SwiperSlide key={index}>
                        <img src={img} alt="Img" />
                      </SwiperSlide>
                    )) : null}
                  </Swiper>
                </div>
              </motion.div>
            ))}
          </div>
        ) : (
          <h3>{wordTranslated.error}</h3>
        )}
      </div>
    </div>
  );
  
};

export default Casos;
