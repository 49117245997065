export const translate = {
  ESP: {
    home: {
      title: "Inteligencia Artificial en tu negocio",
      subtitle:
        "Facilitamos la integración de IA y Machine Learning en tu empresa, ofreciendo tanto modelos personalizados como la provisión de capacitaciones intensivas dirigidas a fortalecer las competencias de tu equipo",
      buttonViewMore: "Contacto",
      services: "Servicios",
      boost: "No te quedes atrás",
      boostSubtitle:
        "Nos encontramos en una ola de innovación, y las empresas que comienzan a adoptar IA ya están gozando de sus beneficios",
      boostButton: "Conocer más",
      clients: "Clientes",
      miniTitle: "Potenciá tu negocio",
    },
    services: {
      miniTitle: "Servicios",
      title: "Transforma tu visión en realidad",
      subtitle:
        "Lo único que nos limita a la hora de crear es la imaginación. Teniendo conocimientos sobre tus problemáticas, podremos desarrollar la solución personalizada perfecta a tus necesidades.",
      title_card1: "Staffing",
      subtitle_card1:
        "Incorpore desarrolladores experimentados rápidamente a su equipo a lo largo del periodo que usted desee y necesite.",
      when: "Cuándo",
      benefit: "Beneficio",
      when_sub_card1:
        "Necesita cubrir necesidades de desarrollo, no dispone de tiempo para largos periodos de contratación",
      benefit_sub_card1: "Aumente o disminuya su equipo según sus necesidades",

      title_card2: "Equipos dedicados",
      subtitle_card2:
        "Incorpore desarrolladores experimentados rápidamente a su equipo a lo largo del periodo que usted desee y necesite.",

      when_sub_card2:
        "Necesita cubrir necesidades de desarrollo, no dispone de tiempo para largos periodos de contratación",
      benefit_sub_card2: "Aumente o disminuya su equipo según sus necesidades",

      title_card3: "Llave en mano",
      subtitle_card3:
        "Enfóquese en su negocio y delegue su proyecto tecnológico. Sea un proyecto nuevo o acelerar uno existente.",
      when_sub_card3:
        "Dispone presupuesto y de metas definidas, Especificaciones del software que necesita definidas",
      benefit_sub_card3:
        "Comienzo del desarrollo en corto plazo, costo predefinidos, olvidese de riesgos de gestión",

      tecnology: "Tecnologías que utilizamos",
      buttonTextCard: "Aplicá ahora",
     },
    solutions:{
      solutions: "Soluciones",
      solutionsTitle: "Soluciones por industria",
      solutionsText: "Si bien ya hablamos anteriormente de que no existe límite para el alcance de las soluciones de IA y ML, te facilitamos un par de posibilidades",
      industriaSalud: "Salud",
      industriaBanca: "Banca",
      industriaEducacion: "Educación",
      industriaIndustria: "Industria",
      industriaSeguros: "Seguros",
      industriaUniversal: "Universal",
    },
    whoWeAre: {
      miniTitle: "Nosotros",
      title: "Quiénes somos",
      subtitle_1:
        "Somos una empresa dedicada al desarrollo de soluciones basadas en software. Acompañamos y potenciamos a nuestros clientes, aportando la visión tecnológica para la transformación y el crecimiento.",
      subtitle_2:
        "Trabajamos adoptando los principios del manifiesto ágil adaptando la metodología particular de cada proyecto a sus características y las necesidades del cliente.",
      subtitle_3:
        "Somos flexibles con el foco puesto en las necesidades reales de nuestros clientes y trabajamos en un marco metodológico producto de años de experiencia.",
      galery: "Galería",
    },
    contact: {
      title: "Contactate con nosotros",
      toptitle: "Contacto",
      altMap: "Mapa Replace",
      emailText: "Correo electrónico",
      phoneText: "Teléfono",
      addressText: "Dirección",
      phoneNumber1: "+54 223 506 4735",
      phoneNumber2: "+54 223 341 7711",
      country: "Argentina,",
      state: "Buenos Aires,",
      city: "Mar del Plata,",
      address: "San Luis 1632",
      error: "Tenemos un problema al enviar los datos",
      nameAndApellidoLabel: "Nombre y Apellido",
      emailLabel: "Correo electrónico",
      motivoLabel: "Motivo",
      wantText: "Quiero",
      radioSelect1: "Contactar al equipo de ventas",
      radioSelect2:
        "Más información sobre los algoritmos de Inteligencia Artificial y Machine Learning",
      successMessage: "¡Gracias! tus datos se enviaron correctamente",
      textButtonState1: "Enviar",
      textButtonState2: "Enviando...",
    },

    success: {
      title: "Nuestros casos de éxito",
      toptitle: "Éxito",
      error: "Hubo un error al cargar los casos",
      cardTitle1: "Swiss Medical Mobile",
      cardTitle2: "Endoclassify",
      cardTitle3: "Errepar",
      cardTitle4: "App Secure AI",
      cardDescription1:
        "Swiss Medical es una de las empresas líderes en el sector de la medicina privada en Argentina. Con más de 40 años de experiencia, Swiss Medical ha brindado servicios de alta calidad a sus pacientes, ofreciendo una amplia gama de prestaciones médicas, hospitalarias y de diagnóstico.",
      cardDescription2:
        "Endoclassify, un líder en la combinación de medicina e IA, destaca en innovación. Conocidos por publicar trabajos pioneros, su producto estrella utiliza IA para analizar ecografías endometriales, evaluando la fertilidad con precisión.",
      cardDescription3:
        "Errepar ofrece al profesional de ciencias económicas un sistema que combina información y análisis a través de sistemas de búsqueda online, publicaciones, aplicaciones tecnológicas, libros, seminarios y el contacto con profesionales especialistas.",
      cardDescription4:
        "AppSecAI es una empresa de seguridad revolucionaria que se especializa en procesos automatizados para probar y corregir vulnerabilidades dentro de los repositorios de código. Aprovechando la IA generativa y diversas técnicas, AppSecAI garantiza la seguridad del código remediado.",
      cardChallenge1:
        "Implementar diferentes módulos de IA en proyectos existentes, incluyendo chatbots, sistemas de clasificación, OCRs y sistemas de análisis de PLN.",
      cardChallenge2:
        "Desarrollar un sistema de detección y clasificación para imágenes endometriales, especialmente ecografías, permitiendo la integración con dispositivos de ecografía.",
      cardChallenge3:
        "Implementar varios asistentes de PLN para mejorar la usabilidad, asegurando que la información pueda ser accesible de manera fluida a través del lenguaje natural. Además, desarrollar sistemas de clasificación y un motor de búsqueda personalizado para optimizar la búsqueda de documentos dentro de Errepar.",
      cardChallenge4:
        "Construir una línea de seguridad integral que abarca todas las etapas: encontrar, priorizar, remediar y validar, utilizando una combinación de técnicas de aprendizaje automático, agentes de IA generativa y pruebas de seguridad estáticas y dinámicas",
      cardTech1: ["Python", "TensorFlow", "Hugging Face", "Langchain"],
      cardTech2: ["C#", "Python", "TensorFlow", ".U-Net", "CNN"],
      cardTech3: ["C#", "Python", "Hugging Face", "Langchain", "Milvus"],
      cardTech4: ["Python", "Groq", "Crew AI", "Keras"],
    },
    footer: {
      title:
        "Aplicá nuestros algoritmos de Machine Learning para resolver problemas de negocio",
      buttonText: "Aplicá ahora",
      replaceText: "© 2024 Replace. Todos los derechos reservados.",
      policyText: "Política de Privacicad",
      cookiesText: "Política de Cookies",
      termsText: "Términos y Condiciones",
    },

    menuListTexts: {
      home: "Home",
      services: "Servicios",
      whoWeAre: "Quienes somos",
      success: "Casos de éxito",
      contact: "Contacto",
    },

    header: {
      home: "Home",
      services: "Servicios",
      whoWeAre: "Quienes somos",
      successfull: "Casos de éxito",
      contact: "Contacto",
    },
  },
  ENG: {
    home: {
      title: "Artificial Intelligence in your business",
      subtitle:
        "We facilitate the integration of AI and Machine Learning in your company, offering both custom models and intensive training aimed at strengthening your team's skills",
      buttonViewMore: "Contact",
      services: "Services",
      boost: "Don't get left behind",
      boostSubtitle:
        "We are in a wave of innovation, and companies that start adopting AI are already enjoying its benefits",
      boostButton: "Learn more",
      clients: "Clients",
      miniTitle: "Empower your business",
    },
    services: {
      miniTitle: "Services",
      title: "Turn your vision into reality",
      subtitle:
        "The only limit to creation is imagination. With knowledge of your challenges, we can develop the perfect custom solution to your needs.",

      title_card1: "Staffing",
      subtitle_card1:
        "Quickly incorporate experienced developers into your team for the period you desire and need.",
      when: "When",
      benefit: "Benefit",
      when_sub_card1:
        "You need to cover development needs, don't have time for long hiring periods",
      benefit_sub_card1:
        "Increase or decrease your team according to your needs",

      title_card2: "Dedicated Teams",
      subtitle_card2:
        "Quickly incorporate experienced developers into your team for the period you desire and need.",

      when_sub_card2:
        "You need to cover development needs, don't have time for long hiring periods",
      benefit_sub_card2:
        "Fully managed services, including auxiliar teams and consultants.",

      title_card3: "Turnkey",
      subtitle_card3:
        "Focus on your business and delegate your technological project. Be it a new project or accelerating an existing one.",
      when_sub_card3:
        "You have a budget and defined goals, Software specifications that you need are defined",
      benefit_sub_card3:
        "Development start in a short term, predefined costs, forget about management risks",

      tecnology: "Technologies we use",
      buttonTextCard: "Apply now",
      },
    solutions: {
      solutions: "Solutions",
      solutionsTitle: "Solutions by industry",
      solutionsText: "Although we have previously discussed that there are no limits to the scope of AI and ML solutions, we offer you a couple of possibilities.",
      industriaSalud: "Health",
      industriaBanca: "Banking",
      industriaEducacion: "Education",
      industriaIndustria: "Industry",
      industriaSeguros: "Insurance",
      industriaUniversal: "Universal",
    },
    whoWeAre: {
      miniTitle: "About Us",
      title: "Who we are",
      subtitle_1:
        "We are a company dedicated to developing software-based solutions. We support and empower our clients, providing the technological vision for transformation and growth.",
      subtitle_2:
        "We work adopting the principles of the agile manifesto, tailoring the methodology of each project to its characteristics and the needs of the client.",
      subtitle_3:
        "We are flexible with a focus on the real needs of our clients and work within a methodological framework resulting from years of experience.",
      galery: "Gallery",
    },
    contact: {
      title: "Get in touch with us",
      toptitle: "Contact",
      altMap: "Map Replace",
      emailText: "Email",
      phoneText: "Phone",
      addressText: "Address",
      phoneNumber1: "+54 223 506 4735",
      phoneNumber2: "+54 223 341 7711",
      country: "Argentina,",
      state: "Buenos Aires,",
      city: "Mar del Plata,",
      address: "San Luis 1632",
      error: "We have a problem sending the data",
      nameAndApellidoLabel: "Name and Surname",
      emailLabel: "Email",
      motivoLabel: "Reason",
      wantText: "I want to",
      radioSelect1: "Contact the sales team",
      radioSelect2:
        "More information about Artificial Intelligence and Machine Learning algorithms",
      successMessage: "Thank you! Your data has been sent successfully",
      textButtonState1: "Send",
      textButtonState2: "Sending...",
    },
    success: {
      title: "Our Success Cases",
      toptitle: "Success",
      error: "There was an error loading the cases",
      cardTitle1: "Swiss Medical Mobile",
      cardTitle2: "Endoclassify",
      cardTitle3: "Errepar",
      cardTitle4: "App Secure AI",
      cardDescription1:
        "Swiss Medical, the top medical ensurance company in Argentina, With more than 40 years of experience, Swiss Medical has provided high-quality services to its patients, offering a wide range of medical, hospital and diagnostinc services.",
      cardDescription2:
        "Endoclassify, a leader in combining medicine and AI, excels in innovation. Known for publishing groundbreaking papers, their flagship product uses AI to analyze endometrial ultrasounds, accurately assessing fertility.",
      cardDescription3:
        "Errepar offers the economic sciences professional a system that combines information and analysis through online search systems, publications, technological applications, books, seminars, and contact with specialist professionals.",
      cardDescription4:
        "AppSecAI is a revolutionary security company specializing in automated processes for testing and fixing vulnerabilities within code repositories. Leveraging generative AI and diverse techniques, AppSecAI ensures the security of remediated code.",
      cardChallenge1:
        "Implement different AI modules within existing projects, including chatbots, classification systems, OCRs and NLP analysis systems.",
      cardChallenge2:
        "Developed a detection and classification system for endometrial images, particularly ultrasounds, enabling integration with ultrasound devices.",
      cardChallenge3:
        "Implement various NLP assistants to enhance usability, ensuring information can be accessed seamlessly through natural language. Additionally, develop classification systems and a custom search engine to optimize document search within Errepar.",
      cardChallenge4:
        "Built a comprehensive security pipeline encompassing all stages-find, triage, remediate, and validate-using a blend of machine learning techniques, generative AI agents, and both static and dynamic security tests.",
      cardTech1: ["Python", "TensorFlow", "Hugging Face", "Langchain"],
      cardTech2: ["React", "React Native", "Typescript", ".NET", "SQLServer"],
      cardTech3: ["C#", "Python", "Hugging Face", "Langchain", "Milvus"],
      cardTech4: ["Python", "Groq", "Crew AI", "Keras"],
    },

    footer: {
      title: "Apply our Machine Learning algorithms to solve business problems",
      buttonText: "Apply now",
      replaceText: "© 2024 Replace. All rights reserved.",
      policyText: "Privacy Policy",
      cookiesText: "Cookie Policy",
      termsText: "Terms and Conditions",
    },

    menuListTexts: {
      home: "Home",
      services: "Services",
      whoWeAre: "Who we are",
      success: "Success Stories",
      contact: "Contact",
    },

    header: {
      home: "Home",
      services: "Services",
      whoWeAre: "Who we are",
      successfull: "Success Stories",
      contact: "Contact",
    },
  }
};
