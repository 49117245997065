import casosImages from '../../../assets/casos/casos.js';

export const casosData = (translatedTexts) => [
    {
        id: 0,
        nombre: translatedTexts.cardTitle1,
        descripcion: translatedTexts.cardDescription1,
        challenge: translatedTexts.cardChallenge1,
        tecnologias: translatedTexts.cardTech1,
        images: casosImages.smg
    },
    {
        id: 1,
        nombre: translatedTexts.cardTitle2,
        descripcion: translatedTexts.cardDescription2,
        challenge: translatedTexts.cardChallenge2,
        tecnologias: translatedTexts.cardTech2,
        images: casosImages.endo
    },
    {
        id: 2,
        nombre: translatedTexts.cardTitle3,
        descripcion: translatedTexts.cardDescription3,
        challenge: translatedTexts.cardChallenge3,
        tecnologias: translatedTexts.cardTech3,
        images: casosImages.errepar
    },
    {
        id: 3,
        nombre: translatedTexts.cardTitle4,
        descripcion: translatedTexts.cardDescription4,
        challenge: translatedTexts.cardChallenge4,
        tecnologias: translatedTexts.cardTech4,
        images: casosImages.appsec
    }
]