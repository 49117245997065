export default {
    smg: [
        require('./smg/SMG_logo.avif'),
        //require('./smg/app.avif'),
    ],
    endo: [
        require('./endo/endo_logo.avif'),
        //require('./smd/app.avif')
    ],
    errepar: [
        require('./errepar/errepar_logo.avif'),
        //require('./errepar/errepar_web.avif')
    ],
    appsec: [
        require('./appsec/app_sec_logo.avif'),
        //require('./tisa/tisa_web.avif')
    ]
}