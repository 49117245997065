import React from "react";
import { adds } from "../../../assets";
import Form from "../Form";
import Medios from "../Medios";
import "./index.css";

const FormDiv = ({ variants }) => {
  return (
    <div className="formDiv section noPadTop" id="form">
      <img className="blur blur1" src={adds.blur1} alt="Blur" />

      <div className="container-lg flex flexRow gap justifySpace flexWrap">
        <Form variants={variants} />
        <Medios variants={variants} />
      </div>
    </div>
  );
};

export default FormDiv;
