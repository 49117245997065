import React from "react";
import { useTranslate } from "../../../assets/utils/useTranslate";
import { motion } from "framer-motion";
import { adds } from "../../../assets";
import "./index.css";

const Medios = ({ variants }) => {
  const wordTranslated = useTranslate("contact");
  const { emailText, phoneText, addressText, country, state, city, address, phoneNumber1, phoneNumber2 } = wordTranslated;
  return (
    <div className="mediosDiv flex flexCol gap">
      <img className="blur blur2" src={adds.blur1} alt="Blur" />

      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={0.3}
        className="flex flexCol"
      >
        <h4>{emailText}</h4>
        <a href="mailto:info@replace.com.ar" target="_blank" rel="noreferrer">
          <p>info@replace.com.ar</p>
        </a>
      </motion.div>
      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={0.5}
        className="flex flexCol"
      >
        <h4>{phoneText}</h4>
        <a href="tel:+542235064735">
          <p>{phoneNumber1}</p>
          <p>{phoneNumber2}</p>
        </a>
      </motion.div>
      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={0.7}
        className="flex flexCol"
      >
        <h4>{addressText}</h4>
        <p>{country}</p>
        <p>{state}</p>
        <p>{city}</p>
        <p>{address}</p>
      </motion.div>
    </div>
  );
};

export default Medios;
