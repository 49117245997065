import logoWhite from "./logos/logoWhite.svg";
import isoWhite from "./logos/isoWhite.svg";
import isoFooter from "./logos/isoFooter.svg";
import isoFullWhite from "./logos/isoWhiteAll.svg";

/* RS */
import facebook from "./rs/fb.svg";
import instagram from "./rs/ig.svg";
import linkedin from "./rs/in.svg";

/* Mapa */
import mapa from "./mapa/mapa.png";

/* Iconos */
import staffing from "./iconos/useradd.svg";
import equipos from "./iconos/people.svg";
import llave from "./iconos/key.svg";
import mantenimiento from "./iconos/setting.svg";
import consultoria from "./iconos/messages.svg";
import contratacion from "./iconos/contratacion.svg";
import plazo from "./iconos/plazo.svg";
import valor from "./iconos/valor.svg";
import beneficio from "./iconos/beneficio.svg";

/* Blur */
import pattern from "./adds/pattern.svg";
import blur1 from "./adds/blur1.png";

/* Tecnologias */
import android from "./tecnologias/android.svg";
import angular from "./tecnologias/angular.svg";
import css from "./tecnologias/css.svg";
import html from "./tecnologias/html.svg";
import java from "./tecnologias/java.svg";
import javascript from "./tecnologias/javascript.svg";
import kubernetes from "./tecnologias/kubernetes.svg";
import mysql from "./tecnologias/mysql.svg";
import net from "./tecnologias/net.svg";
import nodejs from "./tecnologias/nodejs.svg";
import postgres from "./tecnologias/postgres.svg";
import react from "./tecnologias/react.svg";
import swift from "./tecnologias/swift.svg";

/* Fotos */

import image1 from "./fotos/image1.avif";
import image2 from "./fotos/image2.avif";
import image3 from "./fotos/image3.avif";
import image5 from "./fotos/image5.avif";
import image6 from "./fotos/image6.avif";
import image7 from "./fotos/image7.avif";

export const logos = {
  logoWhite: {
    src: logoWhite,
    alt: "Logo Replace",
  },
  isoWhite: {
    src: isoWhite,
    alt: "Isologo Replace",
  },
  isoFooter: {
    src: isoFooter,
    alt: "Isologo Replace",
  },
  isoFullWhite: {
    src: isoFullWhite,
    alt: "IsoLogo",
  },
};

export const rs = {
  in: {
    src: linkedin,
    alt: "Linkedin",
  },
  fb: {
    src: facebook,
    alt: "Facebook",
  },
  ig: {
    src: instagram,
    alt: "Instagram",
  },
};

export const mapaimg = mapa;

export const iconos = {
  staffing: {
    src: staffing,
    alt: "Staffing",
  },
  equipos: {
    src: equipos,
    alt: "Equipos dedicados",
  },
  llave: {
    src: llave,
    alt: "Llave en mano",
  },
  mantenimiento: {
    src: mantenimiento,
    alt: "Soporte y Mantenimiento",
  },
  consultoria: {
    src: consultoria,
    alt: "Consultoría",
  },
  /* Iconos Genericos */
  contratacion: {
    src: contratacion,
    alt: "Contratación",
  },
  plazo: {
    src: plazo,
    alt: "Plazo mínimo",
  },
  valor: {
    src: valor,
    alt: "Valores por hora",
  },
  beneficio: {
    src: beneficio,
    alt: "Beneficio",
  },
};

/* Tecnologias */

export const technologies = {
  android: {
    src: android,
    alt: "Android",
  },
  angular: {
    src: angular,
    alt: "Angular",
  },
  css: {
    src: css,
    alt: "CSS",
  },
  html: {
    src: html,
    alt: "HTML",
  },
  java: {
    src: java,
    alt: "JAVA",
  },
  javascript: {
    src: javascript,
    alt: "JavaScript",
  },
  kubernetes: {
    src: kubernetes,
    alt: "Kubernetes",
  },
  mysql: {
    src: mysql,
    alt: "MySQL",
  },
  net: {
    src: net,
    alt: ".Net",
  },
  nodejs: {
    src: nodejs,
    alt: "Node JS",
  },
  postgres: {
    src: postgres,
    alt: "Postgres",
  },
  react: {
    src: react,
    alt: "React",
  },
  swift: {
    src: swift,
    alt: "Swift",
  },
};

/* Fotos */

export const photos = [
  {
    src: image1,
    alt: "Image 1",
  },
  {
    src: image2,
    alt: "Image 2",
  },
  {
    src: image3,
    alt: "Image 3",
  },
  {
    src: image5,
    alt: "Image 5",
  },
  {
    src: image6,
    alt: "Image 6",
  },
  {
    src: image7,
    alt: "Image 7",
  },
];

/* Adds */

export const adds = {
  pattern: pattern,
  blur1: blur1,
};
