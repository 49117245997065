import React from "react";
import { adds } from "../../../assets";
import Button from "../../../components/Button";
import {useTranslate } from "../../../assets/utils/useTranslate";
import Node from "../../../components/threejs";
import { motion } from "framer-motion";
import "./index.css";

const Hero = ({ variants }) => {
  const wordTranslated = useTranslate('home');
  const {title, subtitle, buttonViewMore, miniTitle} = wordTranslated;

  return (
    <div className="hero flex alignCenter relative">
      <img className="blur blur1" src={adds.blur1} alt="Blur" />
      <div className="container-lg flex flexCol">
        <div className="textContainer flex flexCol">
          <motion.h5
            variants={variants}
            initial={"initial"}
            animate={"ani"}
            custom={0.3}
          >
            {miniTitle}
          </motion.h5>
          <motion.h1
            variants={variants}
            initial={"initial"}
            animate={"ani"}
            custom={0.6}
          >
            {title}
          </motion.h1>
          <motion.p
            variants={variants}
            initial={"initial"}
            animate={"ani"}
            custom={0.9}
          >
            {subtitle}
          </motion.p>
          <Button
            text={buttonViewMore}
            color="white"
            href="/contacto"
            type={"link"}
            variants={variants}
            delay={1.2}
          />
        </div>
        <Node />
      </div>
    </div>
  );
};

export default Hero;
