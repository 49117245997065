// MenuList.js
const MenuList = (menuTexts) => [
  {
    href: "/",
    text: menuTexts.home,
  },
  {
    href: "/servicios",
    text: menuTexts.services,
  },
  {
    href: "/quienes-somos",
    text: menuTexts.whoWeAre,
  },
  {
    href: "/casos-de-exito",
    text: menuTexts.success,
  },
  {
    href: "/contacto",
    text: menuTexts.contact,
  },
];

export default MenuList;
