import React from "react";
import CTAsec from "../../components/CTAsec";
import TitlePages from "../../components/TitlePage";
import { useTranslate } from "../../assets/utils/useTranslate";
import { mapaimg } from "../../assets";
import FormDiv from "./FormDiv";
import { motion } from "framer-motion";
import "./index.css";

const Contacto = ({ variants }) => {
  const wordTranslated = useTranslate("contact");
  const { toptitle, title } = wordTranslated;
  return (
    <>
      <motion.div
        className={"transition"}
        initial={{ x: 0 }}
        animate={{ x: "100%" }}
        exit={{ x: 0 }}
        transition={{
          transition: 3,
          type: "spring",
          stiffness: 200,
        }}
      />
      <div className="containerDiv">
        <TitlePages
          title={title}
          toptitle={toptitle}
          variants={variants}
        />
        <FormDiv variants={variants} />
        <img className="mapa" src={mapaimg} alt="Mapa Replace" />
        <CTAsec href={"/contacto#title"} variants={variants} type={"hash"} />
      </div>
    </>
  );
};

export default Contacto;
