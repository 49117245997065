import React from "react";
import { motion } from "framer-motion";
import "./index.css";
import { iconos } from "../../../assets";
import { Link } from "react-router-dom";

const Item = ({ id, icon, title, resume, beneficio_title, beneficio, variants }) => {
  return (
    <div className="cardContainer">
      <motion.div
        className="card"
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={id * 0.3}
      >
        <div className="titlesDiv flex flexCol">
          <img src={icon} alt={title} />
          <h3>{title}</h3>
          <p>{resume}</p>
          <Link to={"/contacto"} className="plus">
            Aplicar ahora
          </Link>
        </div>
        <div className="infoDiv flex flexRow">
          {/* <div>
            <div className="flex flexRow alignCenter">
              <img src={iconos.plazo.src} alt={iconos.plazo.alt} />
              <h4>Cuándo</h4>
            </div>
            <p>{cuando}</p>
          </div> */}

          <div>
            <div className="flex flexRow alignCenter">
              <img src={iconos.beneficio.src} alt={iconos.beneficio.alt} />
              <h4>{beneficio_title}</h4>
            </div>
            <p>{beneficio}</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Item;
