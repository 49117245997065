import React from "react";
import { motion } from "framer-motion";

const TitlePages = ({ toptitle, title, para, expand, variants }) => {
  return (
    <div className={`titleSec ${expand ? "widthExpand" : ""}`} id="title">
      <div className="container-lg flex flexCol">
        {toptitle && (
          <motion.h5
            variants={variants}
            initial={"initial"}
            whileInView={"ani"}
            viewport={{ once: true }}
            custom={0.3}
          >
            {toptitle}
          </motion.h5>
        )}
        <motion.h1
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.5}
        >
          {title}
        </motion.h1>
        {para && (
          <motion.p
            variants={variants}
            initial={"initial"}
            whileInView={"ani"}
            viewport={{ once: true }}
            custom={0.7}
            className="paraWhite"
          >
            {para}
          </motion.p>
        )}
      </div>
    </div>
  );
};

export default TitlePages;
