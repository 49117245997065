import Item from "../Item";
import { Items } from "./data";
import { useTranslate } from "../../../assets/utils/useTranslate";

const List = ({ variants }) => {
  const translatedTexts = useTranslate("services");
  const dynamicItems = Items(translatedTexts);
  return (
    <div className="section darkSec noPadTop zPos">
      <div className="container-lg flex flexRow flexWrap gap relative">
        {dynamicItems.map((item) => (
          <Item
            key={item.id}
            id={item.id}
            icon={item.icon}
            title={item.title}
            beneficio_title={item.beneficio_title}
            resume={item.resume}
            cuando={item.cuando}
            beneficio={item.beneficio}
            variants={variants}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
