import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Button = ({ type, color, text, href, loading, variants, delay }) => {
  if (type === "submit") {
    return (
      <motion.button
        className={
          (color === "black" &&
            `button blackButton ${loading && "loadBoton"}`) ||
          (color === "whiteAll" &&
            `button allWhiteButtom ${loading && "loadBoton"}`) ||
          `button whiteButton ${loading && "loadBoton"}`
        }
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={delay}
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 1.1 }}
      >
        {text}
      </motion.button>
    );
  } else if (type === "link") {
    return (
      <Link to={href} className="flex">
        <motion.span
          className={
            (color === "black" && "button blackButton") ||
            (color === "whiteAll" && "button allWhiteButtom") ||
            "button whiteButton"
          }
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={delay}
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
        >
          {text}
        </motion.span>
      </Link>
    );
  } else {
    return (
      <motion.a
        className={
          (color === "black" && "button blackButton") ||
          (color === "whiteAll" && "button allWhiteButtom") ||
          "button whiteButton"
        }
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={delay}
        href={href}
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 1.1 }}
      >
        {text}
      </motion.a>
    );
  }
};

export default Button;
