import React from "react";
import { motion } from "framer-motion";
import { photos } from "../../../assets";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow } from "swiper";

import "./index.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import { useTranslate } from "../../../assets/utils/useTranslate";

const Galeria = ({ variants }) => {
  const wordTranslated = useTranslate("whoWeAre");
  const { galery } = wordTranslated;
  return (
    <div className="section darkSec noPadTop">
      <div className="container-lg flex flexCol">
        <motion.h3
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.3}
          className="textCenter"
        >
          {galery}
        </motion.h3>

        <Swiper
          className="galeria"
          effect={"coverflow"}
          modules={[Autoplay, EffectCoverflow]}
          grabCursor={true}
          speed={1500}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
          }}
          loop
          slidesPerView={3}
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <motion.img
                  variants={variants}
                  initial={"initial"}
                  whileInView={"ani"}
                  className={isActive ? "activeSlide" : ""}
                  viewport={{ once: true }}
                  custom={index * 0.2}
                  src={photo.src}
                  alt={photo.alt}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Galeria;
