import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import axios from "axios";
import { motion } from "framer-motion";
import "./index.css";
import "./inputs.css";
import { useTranslate } from "../../../assets/utils/useTranslate";

const Form = ({ variants }) => {
  const wordTranslated = useTranslate("contact");
  const { nameAndApellidoLabel, emailLabel, motivoLabel, wantText, radioSelect1, radioSelect2, textButtonState1, textButtonState2 } = wordTranslated;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [radioSelect, setRadioSelect] = useState(0);

  const handleRadioSelect = (value, text) => {
    setRadioSelect(value);
    setData({
      ...data,
      radio: text,
    });
  };

  const [data, setData] = useState({
    nombreyapellido: "",
    email: "",
    motivo: "",
    radio: "",
  });

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const urlHook = process.env.REACT_APP_WEBHOOK;
  const form = useRef(null);

  const onSubmit = async (formData) => {
    formData.preventDefault();

    setFormLoading(true);
    setError("");

    try {
      await axios
        .post(urlHook, data, {
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then(function (response) {
          form.current.reset();
          //Success ----------------------------------------------------
          console.log(response);
          setError("");
          setSuccess(true);
          setFormLoading(false);
        })

        //End Succes ----------------------------------------------------
        .catch(function (error) {
          //Error ----------------------------------------------------
          console.log(error.response.data.message);
          console.log("Have a problem");
          setError("Tenemos un problema al enviar los datos");
          setFormLoading(false);
        });
      //End Error ----------------------------------------------------
    } catch (error) {
      console.log("Form Error");
      setError("Tenemos un problema al enviar los datos");
      setFormLoading(false);
    }
  };

  return (
    <form
      className="form flex flexCol"
      ref={form}
      onSubmit={(e) => onSubmit(e)}
    >
      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={0.7}
        className={`input ${
          data.nombreyapellido.length > 0 ? "focusInput" : ""
        }`}
        id="nombreyapellido"
      >
        <input
          type="text"
          name="nombreyapellido"
          className="input-field sub-title"
          onChange={handleInputChange}
          value={data.nombreyapellido}
          required
        />
        <label className="input-label">{nameAndApellidoLabel}</label>
      </motion.div>

      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={0.9}
        className={`input ${data.email.length > 0 ? "focusInput" : ""}`}
        id="email"
      >
        <input
          type="email"
          name="email"
          className="input-field sub-title"
          onChange={handleInputChange}
          value={data.email}
          required
        />
        <label className="input-label">{emailLabel}</label>
      </motion.div>

      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={1.1}
        className={`input ${data.motivo.length > 0 ? "focusInput" : ""}`}
        id="motivo"
      >
        <input
          type="text"
          name="motivo"
          className="input-field sub-title"
          onChange={handleInputChange}
          value={data.motivo}
          required
        />
        <label className="input-label">{motivoLabel}</label>
      </motion.div>

      <motion.p
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={1.3}
      >
        {wantText}
      </motion.p>
      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={1.5}
        className={`radioContainer flex flexRow ${
          radioSelect === 0 && "radioContainer-selected"
        }`}
        onClick={() => {
          handleRadioSelect(0, "Contactar al equipo de ventas");
        }}
      >
        <div className={`radio ${radioSelect === 0 && "radio-selected"}`}></div>
        <p>{radioSelect1}</p>
      </motion.div>
      <motion.div
        variants={variants}
        initial={"initial"}
        whileInView={"ani"}
        viewport={{ once: true }}
        custom={1.7}
        className={`radioContainer flex flexRow ${
          radioSelect === 1 && "radioContainer-selected"
        }`}
        onClick={() => {
          handleRadioSelect(
            1,
            "Más información sobre los algoritmos de Inteligencia Artificial y Machine Learning"
          );
        }}
      >
        <div className={`radio ${radioSelect === 1 && "radio-selected"}`}></div>
        <p>
          {radioSelect2}
        </p>
      </motion.div>
      <div className="errores flex flexCol">
        <span className="error">{error}</span>
      </div>

      <div className="success flex flexCol">
        <span>
          {success && "¡Gracias! tus datos se enviaron correctamente"}
        </span>
      </div>
      <Button
        text={formLoading ? textButtonState2 : textButtonState1}
        color="white"
        type={"submit"}
        loading={formLoading}
        variants={variants}
        delay={1.9}
      />
    </form>
  );
};

export default Form;
