export const animation = {
    initial: {
      skew: "3deg, -6deg",
      y: -60,
      opacity: 0,
    },
    ani: (custom) => ({
      skew: "0deg, 0deg",
      y: 0,
      opacity: 1,
      transition: {
        delay: custom,
        transition: 4,
        type: "spring",
        stiffness: 200,
      },
    }),
};