import React from "react";
import { useTranslate } from "../../../assets/utils/useTranslate";
import Button from "../../../components/Button";
import { adds } from "../../../assets";
import { motion } from "framer-motion";
import "./index.css";

const Ingresos = ({ variants }) => {
  const wordTranslated = useTranslate('home');
  const {services, boost, boostSubtitle, boostButton} = wordTranslated

  return (
    <div className="ingresos section relative">
      <img className="blur blur1" src={adds.blur1} alt="Blur" />

      <div className="container-lg flex flexCol alignCenter textCenter">
        <motion.h5
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.3}
        >
          {services}
        </motion.h5>
        <motion.h2
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.6}
        >
          {boost}
        </motion.h2>
        <motion.p
          variants={variants}
          initial={"initial"}
          whileInView={"ani"}
          viewport={{ once: true }}
          custom={0.9}
          className="parDes"
        >
          {boostSubtitle}
        </motion.p>
        <Button
          color={"white"}
          text={boostButton}
          href={"/servicios"}
          type={"link"}
          variants={variants}
          delay={1.2}
        />
      </div>
    </div>
  );
};

export default Ingresos;
