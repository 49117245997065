import React from "react";
import "./index.css";

const CTAsec = ({ href, type, variants }) => {
  return (
    <div className="ctaSec sectionBar colorSec">
      <div className="container-lg flex flexRow alignCenter justifySpace flexWrap">
      </div>
    </div>
  );
};

export default CTAsec;
