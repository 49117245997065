import React from "react";
import { useTranslate } from "../../../assets/utils/useTranslate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ClientList } from "./ClientesList";
import "./index.css";


const Clientes = ({ variants }) => {
  const wordTranslated = useTranslate("home");
  const { clients } = wordTranslated;
  return (
    <div className="clientes section relative noPadTop">
      <div className="container-lg flex flexCol">
        <div className="flex flexCol">
          <motion.h3
            variants={variants}
            initial={"initial"}
            whileInView={"ani"}
            viewport={{ once: true }}
            custom={0.3}
          >
            {clients}
          </motion.h3>
          <motion.div
            variants={variants}
            initial={"initial"}
            whileInView={"ani"}
            viewport={{ once: true }}
            custom={0.6}
          >
            <Swiper
              className="clientSwiper"
              modules={[Autoplay]}
              spaceBetween={50}
              grabCursor={false}
              speed={5000}
              autoplay={{
                delay: 5,
              }}
              loop
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
            >
              {ClientList.map((client, index) => (
                <SwiperSlide key={index}>
                  <img src={client.src} alt={client.alt} />
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Clientes;
