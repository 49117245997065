import { iconos } from "../../../assets";

// data.js
export const Items = (translatedTexts) => [
  {
    id: 1,
    icon: iconos.staffing.src,
    title: translatedTexts.title_card1,
    resume: translatedTexts.subtitle_card1,
    cuando: translatedTexts.when_sub_card1,
    beneficio: translatedTexts.benefit_sub_card1,
    beneficio_title: translatedTexts.benefit
  },
  {
    id: 2,
    icon: iconos
    .equipos.src,
    title: translatedTexts.title_card2,
    resume: translatedTexts.subtitle_card2,
    cuando: translatedTexts.when_sub_card2,
    beneficio: translatedTexts.benefit_sub_card2,
    beneficio_title: translatedTexts.benefit
  },
  {
    id: 3,
    icon: iconos.llave.src,
    title: translatedTexts.title_card3,
    resume: translatedTexts.subtitle_card3,
    cuando: translatedTexts.when_sub_card3,
    beneficio: translatedTexts.benefit_sub_card3,
    beneficio_title: translatedTexts.benefit
  },
];
