import errepar from "../../../assets/clientes/errepar.png";
import itbc from "../../../assets/clientes/itbc.png";
import intermedia from "../../../assets/clientes/intermedia.png";
import adviters from "../../../assets/clientes/adviters.png";
import tisa from "../../../assets/clientes/tisa.png";
import swiss from "../../../assets/clientes/swiss.png";
import mater from "../../../assets/clientes/mtd.png";
import drogueria from "../../../assets/clientes/drogueria.png";

export const ClientList = [
  {
    src: errepar,
    alt: "Errepar",
  },
  {
    src: itbc,
    alt: "ITBC",
  },
  {
    src: intermedia,
    alt: "Intermedia",
  },
  {
    src: adviters,
    alt: "Adviters",
  },
  {
    src: tisa,
    alt: "TISA",
  },
  {
    src: swiss,
    alt: "Swiss Medical",
  },
  {
    src: mater,
    alt: "Sanatorio Mater Dei",
  },
  {
    src: drogueria,
    alt: "Droguería",
  }
];
