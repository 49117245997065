import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "../../assets/utils/useTranslate";
import { logos, rs } from "../../assets";
import MenuList from "../MenuList";
import { Link } from "react-router-dom";
import "./index.css";

const Footer = ({ windowDimension }) => {
  const wordTranslated = useTranslate("footer");
  const menuTexts = useTranslate("menuListTexts");
  const { replaceText, policyText, cookiesText, termsText } = wordTranslated;
  const dynamicMenuList = MenuList(menuTexts);
  const offset = useRef(null);
  const footer = useRef(null);
  const [offsetMargin, setOffsetMargin] = useState(null);

  useEffect(() => {
    return setOffsetMargin(footer.current.clientHeight);
  }, [windowDimension]);

  const RSlist = [
    {
      href: "https://www.instagram.com/",
      src: rs.ig.src,
      alt: rs.ig.alt,
    },
    {
      href: "https://www.linkedin.com/company/replaceit/",
      src: rs.in.src,
      alt: rs.in.alt,
    },
  ];

  return (
    <>
      <div
        className="offset"
        style={{
          height: offsetMargin + "px",
        }}
        ref={offset}
      ></div>
      <footer
        className="footer flex flexCol vertCenter justifyEnd"
        ref={footer}
      >
        <div className="container-lg flex flexCol alignCenter">
          <img
            className="footerLogo"
            src={logos.isoFooter.src}
            alt={logos.isoFooter.alt}
          />
          <nav className="menuFooter flex flexRow gap">
            {dynamicMenuList.map((menu, index) => (
              <Link className="menuLinkFooter" to={menu.href} key={index}>
                {menu.text}
              </Link>
            ))}
          </nav>
          <div className="rs flex flexRow justifySpace">
            {RSlist.map((social, index) => (
              <a
                href={social.href}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <img src={social.src} alt={social.alt} />
              </a>
            ))}
          </div>
          <div className="footerCopy flex flexRow justifySpace">
            <p>{replaceText}</p>
            <p>
              <a href="#priv">{policyText}</a> |{" "}
              <a href="#cookies">{cookiesText}</a> |{" "}
              <a href="#term">{termsText}</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
